import { Injectable } from '@angular/core';

import * as io from 'socket.io-client';
import { Observable, Subject, observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  mainPage_socket: any;
  socket: any;
  connection: string = '';

  constructor() { }

  setupSocketConnection(token: any) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        console.log('connecting...');
        // if (token) {
        // this.socket = io.connect(this.ApiBackEndService.getBackEnd_url(), { query: { token: token } });
        // this.socket = io.connect({ 'path': '/socket.io' }, { query: { token: token } });
        this.socket = io.connect('/', { query: { token: token } });
        this.connection = 'Connected';
        this.socket.on('disconnect', (data: any) => {
          this.connection = 'Disconnected';
        });
        this.socket.on('reconnect', (data: any) => {
          this.connection = 'Connected';
        });
        resolve('Connected');
      }
      catch (err) {
        reject(err);
      }
    })
  }

  public test_get(){
    return new Observable((obs: any) => {
      this.socket.on('test', (data: any) => {
        
      })
    })
  }

  public test_send(data:any){
    return new Promise<any>((resolve, reject) => {
      if (this.connection == 'Disconnected') reject('Socket Disconnected');
      this.socket.emit('test_send', data);
      resolve('OK');
    });
  }

}
