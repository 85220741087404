<!-- <div class="banner">
  <img *ngIf="!mobile" src="/api/banner/{{ id }}/banner_desktop.webp" />
  <img *ngIf="mobile" src="/api/banner/{{ id }}/banner_mobile.webp" />
</div> -->

<div class="divImgBanner" style="margin-bottom: 5px">
  <img
    class="imgBanner d-none d-lg-block"
    src="/api/banner/{{ id }}/banner_desktop.webp"
    width="100%"
    alt=""
    loading="lazy"
  />
  <img
    class="imgBanner d-none d-md-block d-lg-none"
    src="/api/banner/{{ id }}/banner_desktop.webp"
    width="100%"
    alt=""
    loading="lazy"
  />
  <img
    class="imgBanner d-block d-md-none"
    src="/api/banner/{{ id }}/banner_mobile.webp"
    width="100%"
    alt=""
    loading="lazy"
  />
  <!-- <img class="imgBanner d-none d-lg-block" src="assets/img/Banner2.png" width="100%" alt="" />
  <img
    class="imgBanner d-none d-md-block d-lg-none"
    src="assets/img/Banner2-Tablet.png"
    width="100%"
    alt=""
  />  
  <img
    class="imgBanner d-block d-md-none"
    src="assets/img/Banner2-Mobile.png"
    width="100%"
    alt=""
  /> -->
</div>
<div class="gallery">
  <div
    class="item"
    *ngFor="let photo of photos | pagination : { currentPage: pageIndex, itemsPerPage: pageSize }"
  >
    <img src="{{ photo.url }}" (click)="openModal(photo)" loading="lazy" />
  </div>
</div>
<div class="bg-white fixed-bottom">
  <mat-paginator
    [length]="photos.length"
    [pageSize]="pageSize"
    [pageSizeOptions]="[50, 100, 150, 200]"
    [pageIndex]="pageIndex"
    (page)="onHandlePage($event)"
    previousPageLabel="Previous"
    nextPageLabel="Next"
  >
  </mat-paginator>
</div>
<div style="position: fixed; top: 85%" [ngStyle]="{ left: windowSize <= 850 ? '85%' : '95%' }">
  <button mat-mini-fab color="primary" (click)="getAllImage()">
    <mat-icon>refresh</mat-icon>
  </button>
</div>

<!-- <div class="modal" [ngClass]="{'open': showModal}">
    <span class="close" (click)="closeModal()">&times;</span>
    <div class="modal-content">
        <img class="image" [src]="modalImage" alt="">
        <div class="qrcode" #qrCode></div>
    </div>
</div> -->

<div
  bsModal
  #myModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
>
  <div class="myDialog">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center">
        <p class="modal-title h4">SCAN TO DOWNLOAD</p>
        <button type="button" class="btn-close close" (click)="myModal.hide()" aria-label="Close">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="selectedPhoto">
        <mat-grid-list
          [cols]="windowSize <= 900 ? 1 : 3"
          [rowHeight]="windowSize <= 900 ? '300px' : '500px'"
        >
          <mat-grid-tile [colspan]="windowSize <= 900 ? 1 : 2">
            <img
              src="{{ selectedPhoto.url }}"
              class="img-fluid img-thumbnail"
              style="max-height: 100%"
            />
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <div class="test">
              <qrcode *ngIf="selectedPhoto.originalUrl"
                [qrdata]="selectedPhoto.originalUrl"
                [width]="300"
                [errorCorrectionLevel]="'M'"
              ></qrcode>
              <h3>
                {{selectedPhoto.filename}}
              </h3>
              <br />
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div class="modal-footer">
        <button
          mat-raised-button
          color="secondary"
          (click)="printImage(selectedPhoto)"
          class="mx-2"
        >
          Print
        </button>
        <button mat-raised-button color="warn" (click)="hide_image(selectedPhoto)" class="mx-2">
          Hide
        </button>
        <button mat-flat-button color="primary" (click)="myModal.hide()" class="mx-2">Close</button>
      </div>
    </div>
  </div>
</div>
