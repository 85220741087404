import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ApiFrontEndService } from '../services/api-front-end.service'
import { EncdecService } from '../services/encdec.service'
import QRCode from 'qrcode'
import { Buffer } from 'buffer'
import { saveAs } from 'file-saver'
import JSZip, { file } from 'jszip'
import { DummyDialogComponent } from '../dummy-dialog/dummy-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'
import { NotificationService } from '../notification.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { fromEvent } from 'rxjs'
import { switchMap, takeUntil } from 'rxjs/operators'
import { ImageDialogComponent } from '../dialog/image-dialog/image-dialog.component'
import { UploadBannerDialogComponent } from '../dialog/upload-banner-dialog/upload-banner-dialog.component'
import { FileUploadService } from '../services/file-upload.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss']
})
export class ConsoleComponent implements OnInit {
  @ViewChild('myModal') myModal

  id: any
  photos = []
  modalRef: BsModalRef
  selectedPhoto: any = {}
  originalUrl: String = ''
  timeoutHandler: any
  windowSize = window.innerWidth
  selectedPhotoLength = 0
  dummyNumber: any
  timer: any
  preventSimpleClick: any
  private touchtime = 0
  private selectedTouch

  @ViewChild('image') image: ElementRef
  type: any
  constructor(
    private API: ApiFrontEndService,
    private router: Router,
    private route: ActivatedRoute,
    private encdec: EncdecService,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private notifyService: NotificationService,
    private el: ElementRef,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      let id = params.get('id')
      this.check_exist(id)
    })
    this.type = 'original'
  }

  async check_exist(id) {
    let exist = await this.API.api_post('check_exist', { id })
    if (exist) {
      this.id = id
      this.getAllImage()
    } else {
      return this.router.navigate([''])
    }
  }

  showGenerateDummyDialog() {
    this.myModal.show()
  }

  async getAllImage() {
    try {
      this.photos = []
      let results: Array<any> = await this.API.api_post('get_images', {
        id: this.id,
        type: this.type
      })
      for (var i = 0; i < results.length; i++) {
        var data = {
          type: 'compress',
          folder: this.id,
          filename: results[i]
        }
        let encrypted = this.encdec.encrypt('key', data)
        let inside_data = {
          filename: results[i],
          url: `${window.location.origin}/api/photo/images/${encrypted}`,
          selected: false
        }
        this.photos.push(inside_data)
      }
    } catch (err) {}
  }

  async switchToType(currentType: any) {
    let updateData = {}
    updateData['id'] = this.id
    if (currentType == 'original') {
      updateData['switchType'] = 'recyle'
      updateData['photos'] = this.photos.filter((v) => v.selected == true)
    } else {
      updateData['switchType'] = 'original'
      updateData['photos'] = this.photos.filter((v) => v.selected == true)
    }
    if (updateData['photos'].length == 0) return
    try {
      let results = await this.API.api_post('switch_photos', updateData)
      if (results == 'OK') {
        this.getAllImage()
        this.modalRef.hide()
        this.selectedPhotoLength = 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  async downloadAll() {
    try {
      this.spinner.show()
      this.API.downloadAllPictures({ id: this.id }).subscribe((res) => {
        if (res.body) {
          saveAs(res.body, `${this.id}.zip`)
          this.spinner.hide()
        }
      })
    } catch (err) {
    } finally {
    }
    // console.log(result);
  }

  switchTo(type: any) {
    if (this.type == type) return
    this.type = type
    this.getAllImage()
  }

  onHandleImage(photo) {
    photo.selected = !photo.selected
    this.photos = this.photos.map((e) => (e.filename == photo.filename ? photo : e))
    this.selectedPhotoLength = this.photos.filter((e) => e.selected).length
  }

  openModal(template: any, photo: any) {
    this.selectedPhoto = { ...photo }
    var data = {
      type: 'original',
      folder: this.id,
      filename: this.selectedPhoto.filename
    }
    let encrypted = this.encdec.encrypt('key', data)
    this.selectedPhoto.originalUrl = `${window.location.origin}/api/photo/images/${encrypted}`
    this.modalRef = this.modalService.show(template)
  }

  openUploadBannerDialog(): void {
    const dialogRef = this.dialog.open(UploadBannerDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: { id: this.id }
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DummyDialogComponent, {
      data: { id: this.id }
    })

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (result == 'OK') {
          this.notifyService.showSuccess('DUMMY CREATED SUCCESSFUL', 'PLEASE CHECK DOWNLOADS')
          this.getAllImage()
        } else {
          this.notifyService.showError('An error occured', 'UNABLE TO CREATE')
        }
      }
    })
  }

  async saveLink() {
    let selectedPhotos = this.photos.filter((v) => v.selected == true)
    const zip = new JSZip()
    for (var i in selectedPhotos) {
      let fileName = selectedPhotos[i].filename
      let data = {
        type: 'original',
        folder: this.id,
        filename: fileName
      }
      let encrypted = this.encdec.encrypt('key', data)
      let url = `${window.location.origin}/api/photo/images/${encrypted}`
      var qr = await QRCode.toDataURL(url, { scale: 15 })
      const base64 = qr.split(',')[1]
      const buffer = Buffer.from(base64, 'base64')
      zip.file(`${fileName}`, buffer.toString('base64'), { base64: true })
    }
    const zipBlob = await zip.generateAsync({ type: 'blob' })

    saveAs(zipBlob, `qrcodes.zip`)
  }

  openImage(photo) {
    this.dialog.open(ImageDialogComponent, {
      data: photo,
      width: '90%',
      maxHeight: '100vh',
      maxWidth: '80%'
    })
  }
}
