import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pagination',
  pure: false
})
export class PaginationPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }
    return items.slice(filter['currentPage'] * filter['itemsPerPage'], (filter['currentPage'] + 1) * filter['itemsPerPage']);
  }
}