

<!-- <img class="background-image" src="/assets/images/login_background.jpg" alt=""> -->

<div class="login-body">
    <div class="login-form">
        <form [formGroup]="loginForm" (ngSubmit)="submit()">
          <div *ngIf="errMsg" class="errMsg">
            <p>{{ errMsg }}</p>
          </div>
          <div class="form-group">
            <label>Username: </label>
            <input class="form-control" type="text" formControlName="username" />
          </div>
          <div class="form-group">
            <label>Password: </label>
            <input class="form-control" type="password" formControlName="password" />
          </div>
          <div class="login-btn">
            <input class="btn btn-primary login-btn" type="submit" value="Login" />
          </div>
        </form>
      </div>
</div>
