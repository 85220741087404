import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
@Injectable({ providedIn: "root" })
// @Injectable({
//   providedIn: "root",
// })
export class FileUploadService {
  constructor(private http: HttpClient) {}
  getBackEnd_url() {
    // return 'http://localhost:3000/api';
    return "/file";
  }

  upload_video(data, id) {
    return this.http
      .post(`${this.getBackEnd_url()}/upload_video/?id=${id}`, data, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(catchError(this.errorMgmt));
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
  upload_banner(data, id, type) {
    return this.http
      .post(
        `${this.getBackEnd_url()}/upload_banner/?id=${id}&type=${type}`,
        data,
        {
          reportProgress: true,
          observe: "events",
          responseType: "json",
        }
      )
      .pipe(catchError(this.errorMgmt));
  }
}
