import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AccountLoginComponent } from "./account/account-login/account-login.component";
import { ConsoleComponent } from "./console/console.component";
import { GalleryNewComponent } from "./gallery-new/gallery-new.component";
import { GalleryComponent } from "./gallery/gallery.component";

const routes: Routes = [  
  { path: ":id", component: GalleryNewComponent },
  { path: ":id/admin", component: ConsoleComponent },
  {path: "gallery/:id", component: GalleryComponent}
];

@NgModule({  
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
