import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { ApiBackEndService } from "./services/api-back-end.service";
import { ApiFrontEndService } from "./services/api-front-end.service";
import { DataService } from "./services/data.service";
import { SocketioService } from "./services/socketio.service";
import {
  AuthInterceptor,
  authInterceptorProviders,
} from "./services/auth.interceptor";
import { AuthGuard } from "./services/auth.guard";
import { AuthService } from "./services/auth.service";

import { ModalModule } from "ngx-bootstrap/modal";
import { NgxSpinnerModule } from "ngx-spinner";
import { AccountLoginComponent } from "./account/account-login/account-login.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { FormService } from "./services/form.service";
import { EncdecService } from "./services/encdec.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { QRCodeModule } from "angularx-qrcode";
import { ConsoleComponent } from "./console/console.component";
import { MaterialModule } from "./material.module";
import { PaginationPipe } from "./services/pagination.pipe";
import { NgxPaginationModule } from "ngx-pagination";
import { GalleryNewComponent } from "./gallery-new/gallery-new.component";
import { DummyDialogComponent } from "./dummy-dialog/dummy-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { ToastrModule } from "ngx-toastr";
import { NotificationService } from "./notification.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { LongPressDirective } from "./directives/longpress.directive";
import { ImageDialogComponent } from "./dialog/image-dialog/image-dialog.component";
import { UploadBannerDialogComponent } from "./dialog/upload-banner-dialog/upload-banner-dialog.component";
import { FileUploadService } from "./services/file-upload.service";

@NgModule({
  declarations: [
    AppComponent,
    AccountLoginComponent,
    GalleryComponent,
    ConsoleComponent,
    PaginationPipe,
    GalleryNewComponent,
    DummyDialogComponent,
    LongPressDirective,
    ImageDialogComponent,
    UploadBannerDialogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    MaterialModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    ToastrModule.forRoot({ positionClass: "toast-top-center" }),
    NgbModule,
    MatDialogModule,
    MatInputModule,
    QRCodeModule,
    NgxPaginationModule,
    MaterialModule,
  ],
  providers: [
    ApiBackEndService,
    ApiFrontEndService,
    AuthInterceptor,
    FileUploadService,
    AuthGuard,
    NotificationService,
    AuthService,
    authInterceptorProviders,
    SocketioService,
    DataService,
    FormService,
    EncdecService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
