import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldControl } from "@angular/material/form-field";
import * as moment from 'moment';
import QRCode from "qrcode";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import JSZip, { file } from "jszip";
import { NgxSpinnerService } from "ngx-spinner";
import { EncdecService } from "../services/encdec.service";
import { ApiFrontEndService } from "../services/api-front-end.service";


@Component({
  selector: "app-dummy-dialog",
  templateUrl: "./dummy-dialog.component.html",
  styleUrls: ["./dummy-dialog.component.scss"],
})
export class DummyDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('prefix') prefixElement: ElementRef;
  myForm: FormGroup;
  example: String;
  allowSubmit: boolean;
  result: any;
  constructor(
    public dialogRef: MatDialogRef<DummyDialogComponent>,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private encdec: EncdecService,
    private API: ApiFrontEndService
  ) {}
  ngAfterViewInit(): void {
    
      // this.prefixElement.nativeElement.focus();
    
  }
  rateControl = new FormControl("", [Validators.max(100), Validators.min(1)]);
  id: any;
  ngOnInit(): void {
    this.result = null;
    this.allowSubmit = false;
    this.id = this.data.id;
    this.myForm = this.fb.group({
      number: [
        1,
        Validators.compose([
          Validators.required,
          Validators.max(500),
          Validators.min(1),
        ]),
      ],
      prefix: [
        "",
        Validators.compose([Validators.required])
      ]
    });      
  }

  onChange(){
    this.allowSubmit = false;
    this.result = null;
    this.example = null;
  }
  

  async checkValid(){
    let data = {
      folderName: this.id,
      ...this.myForm.value
    }
    let result = await this.API.api_post('check_valid', data);    
    if(result == 'ERROR'){

    }
    else{
      this.result = result;
      this.example = `${this.myForm.value.prefix}_${result.start} ${result.end == result.start ? '': `to ${this.myForm.value.prefix}_${result.end}`}`;
      this.allowSubmit = true;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  async submit(){
    if (this.myForm.valid) {      
      try{
        this.spinner.show();
        await this.createDummyImage(this.myForm.get("prefix").value,this.myForm.get("number").value);                
        this.spinner.hide();
        this.dialogRef.close("OK");
       }
       catch(err){
        this.spinner.hide();
        this.dialogRef.close("ERROR");
       }
      
    }
  }

  async createDummyImage(prefix: String, count: number) {
    return new Promise(async (resolve, reject) => {
      try {
        // var prefix = `${moment().format("YYMMDD_HHmmss_")}`;
        const zip = new JSZip();

        var arr = [];        
        for (var i = 0; i < count; i++) {
          let fileName;
          if(i == 0){
            fileName = `${prefix}_${this.result.start}.jpeg`;
          }
          else{
            fileName = `${prefix}_${this.result.start + i}.jpeg`;
          }
          var data = {
            type: "original",
            folder: this.id,
            filename: fileName,
          };
          let encrypted = this.encdec.encrypt("key", data);
          let url = `${window.location.origin}/api/photo/images/${encrypted}`;
          var qr = await QRCode.toDataURL(url, { scale: 15 });
          const base64 = qr.split(",")[1];
          const buffer = Buffer.from(base64, "base64");
          zip.file(`${fileName}`, buffer.toString("base64"), { base64: true });
          arr.push({ fileName, url });
        }      
        const zipBlob = await zip.generateAsync({ type: "blob" });

        saveAs(zipBlob, `${this.id}_qrcodes.zip`);
        await this.API.api_post("create_dummy_photos", {
          folderName: this.id,
          files: arr,
        });
       
        resolve("OK");
      } catch (err) {
        reject(err);
      }
    });
  }

  
}
