import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiFrontEndService } from '../services/api-front-end.service'
import { EncdecService } from '../services/encdec.service'
import QRCode from 'qrcode'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { Renderer2 } from '@angular/core'
interface Image {
  src: string
  alt: string
}

@Component({
  selector: 'app-gallery-new',
  templateUrl: './gallery-new.component.html',
  styleUrls: ['./gallery-new.component.scss']
})
export class GalleryNewComponent implements OnInit {
  constructor(
    private API: ApiFrontEndService,
    private encdec: EncdecService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {}

  @ViewChild('myModal') myModal
  modalRef: BsModalRef
  showModal = false
  modalImage = ''
  images: Image[] = []
  id: any
  photos = []
  selectedPhoto: any = {}
  originalUrl: String = ''
  @ViewChild('qrCode') qrCode: ElementRef

  pageIndex: number = 0
  page = 1
  pageSize = 50
  windowSize = window.innerWidth
  scrHeight: any
  scrWidth: any
  mobile: boolean = false
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight
    this.scrWidth = window.innerWidth
    this.change_navbar(this.scrHeight, this.scrWidth)
  }

  change_navbar(height, width) {
    if (width > 1000) {
      this.mobile = false
    } else {
      this.mobile = true
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      let id = params.get('id')
      this.check_exist(id)
    })
    this.getScreenSize();
  }

  async generateQRCode(text: string) {
    try {
      const element = this.qrCode.nativeElement
      const options = { width: 256, margin: 0 }
      const qrCodeImage = await QRCode.toDataURL(text, options)
      element.innerHTML = `<img src="${qrCodeImage}" alt="QR code"/>`
    } catch (error) {
      console.error(error)
    }
  }

  async check_exist(id) {
    let exist = await this.API.api_post('check_exist', { id })
    if (exist) {
      this.id = id
      this.getAllImage()
    } else this.router.navigate(['/'])
    var timer = setInterval(() => {
      this.getAllImage()
    }, 20000)
  }

  async getAllImage() {
    try {
      let results: any = await this.API.api_post('get_images', {
        id: this.id
      })
      let photos = []
      for (var i = 0; i < results.length; i++) {
        var data = {
          type: 'compress',
          folder: this.id,
          filename: results[i]
        }
        let encrypted = this.encdec.encrypt('key', data)
        let inside_data = {
          filename: results[i],
          url: `${window.location.origin}/api/photo/images/${encrypted}`
        }
        photos.push(inside_data)
      }
      this.photos = photos
    } catch (err) {}
  }

  printImage(data) {
    // const printWindow = window.open("", "_blank");
    // const img = new Image();
    // img.src = data.originalUrl;
    // img.onload = function () {
    //   const style = "max-width:100%; max-height:100%;";
    //   printWindow.document.write("<html><body>");
    //   printWindow.document.write(
    //     '<img src="' +
    //       img.src +
    //       '" style="border: 5px solid #555;' +
    //       style +
    //       '">'
    //   );
    //   printWindow.document.write("</body></html>");
    //   printWindow.document.close();
    //   setTimeout(function () {
    //     printWindow.print();
    //   }, 500);
    //   printWindow.onfocus = function () {
    //     setTimeout(function () {
    //       printWindow.close();
    //     }, 500);
    //   };
    // };

    const newTab = window.open('', '_blank')
    const image = new Image()
    image.src = data.originalUrl

    newTab.onload = () => {
      let imageElement = newTab.document.createElement('img')
      imageElement.src = image.src
      const imageStyle = imageElement.style
      imageStyle.maxWidth = '100%'
      imageStyle.maxHeight = '100%'
      imageStyle.border = '5px solid #555'

      // Add a border style to the body of the new tab
      const bodyStyle = newTab.document.body.style
      // bodyStyle.border = "1px solid black"; // Replace with your desired border style

      newTab.document.body.appendChild(imageElement)

      // Print the image in the new tab
      imageElement.onload = () => {
        setTimeout(function () {
          newTab.print()
        }, 500)
      }

      // Close the tab after printing or not printing
      newTab.onafterprint = () => {
        setTimeout(() => {
          newTab.close()
        }, 500)
      }
      newTab.onbeforeunload = () => {
        setTimeout(() => {
          newTab.close()
        }, 500)
      }
    }

    // Handle error loading the image
    image.onerror = () => {
      console.error('Error loading image')
      newTab.close()
    }
  }

  // openModal(photo: any) {
  //  try{
  //   this.selectedPhoto = {...photo};
  //   var data = {
  //     type: "original",
  //     folder: this.id,
  //     filename: this.selectedPhoto.filename,
  //   };
  //   let encrypted = this.encdec.encrypt("key", data);
  //   this.selectedPhoto.originalUrl = `${window.location.origin}/api/photo/images/${encrypted}`;
  //   this.modalImage = this.selectedPhoto.url;
  //   this.generateQRCode(this.modalImage);
  //   this.showModal = true;
  //  }
  //  catch(err){
  //   console.error(err);
  //  }
  // }

  // closeModal() {
  //   this.showModal = false;
  // }

  openModal(photo: any) {
    this.selectedPhoto = { ...photo }
    var data = {
      type: 'original',
      folder: this.id,
      filename: this.selectedPhoto.filename
    }
    let encrypted = this.encdec.encrypt('key', data)

    this.selectedPhoto.originalUrl = `${window.location.origin}/api/photo/images/${encrypted}`
    this.myModal.show()
  }

  async hide_image(photo: any) {
    try {
      let updateData: {
        id: string
        switchType: string
        photos: any[]
      } = {
        id: this.id,
        switchType: 'recyle',
        photos: [photo]
      }
      if (updateData.photos.length == 0) return
      let results = await this.API.api_post('switch_photos', updateData)
      if (results == 'OK') {
        this.getAllImage()
        this.myModal.hide()
      }
    } catch (err) {
      console.error(err)
    }
  }

  onHandlePage(e) {
    this.pageIndex = e.pageIndex
    this.pageSize = e.pageSize
  }
}
