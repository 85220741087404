
<div mat-dialog-title class="dialog-title">
    <h2>Photo</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
<div mat-dialog-content>
  <div class="image-container">
    <img width="100%" height="100%" class="image" src="{{data.url}}" alt="">
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onCancelClick()">Cancel</button>
</div>