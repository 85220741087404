import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router, ActivatedRoute, Params } from "@angular/router";

import { ApiFrontEndService } from "../services/api-front-end.service";
import { EncdecService } from "../services/encdec.service";
import { AuthService } from "../services/auth.service";
import { SocketioService } from "../services/socketio.service";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(
    private Router: Router,
    private API: ApiFrontEndService,
    private EncdecService: EncdecService,
    private AuthService: AuthService,
    private SocketioService: SocketioService,
    private router: Router
  ) {}

  private user = new BehaviorSubject(undefined);
  current_user = this.user.asObservable();
  update_user(value: any) {
    this.user.next(value);
  }

  private socket = new BehaviorSubject(undefined);
  socket_on = this.socket.asObservable();
  update_socket(value: any) {
    this.socket.next(value);
  }

  private vote = new BehaviorSubject({
    question: undefined,
    answers: undefined,
    action: undefined,
  });
  current_vote = this.vote.asObservable();
  update_vote(value: any) {
    this.vote.next(value);
  }

  
  async construct_user() {
    try {
      let token = this.AuthService.getToken();
      if (token) {
        let decoded = this.AuthService.jwt_decode(token);
        if (!decoded["name"] && decoded["nickname"])
          decoded["name"] = decoded["nickname"];
        decoded["token"] = token;
        this.update_user(decoded);
        return 'OK';
      }
      else{
        // return this.router.navigate([""]);
        return 'NO';
      }

      
    } catch (err) {
      console.error(err);
    }
  }

  verifyToken() {
    return new Promise(async (resolve, reject) => {
      try {
        let logined = await this.AuthService.verifyToken();
        this.construct_user();
        if (!this.SocketioService["socket"]) {
          this.SocketioService.setupSocketConnection(
            this.AuthService.getToken()
          );
          this.update_socket("ok");
        }
        resolve(logined);
      } catch (err) {
        reject(err);
      }
    });
  }
}
