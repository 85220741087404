import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  // CanActivateChild, CanDeactivate, CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";

import { AuthService } from "./auth.service";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private Router: Router,
    private AuthService: AuthService,
    private DataService: DataService
  ) {}

  async canActivate(
    ActivatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let redirectTo = "";
    // 2nd: verify if user_type == 'Admin'
    if (ActivatedRouteSnapshot["data"]) {
      let verified_user_type: any = false;
      if (ActivatedRouteSnapshot["data"][0] == "ok")
        verified_user_type = await this.AuthService.verifyToken();
      if (ActivatedRouteSnapshot["data"][0] == "admin")
        verified_user_type = this.AuthService.verify_user_type("admin");
      if (!verified_user_type) {
        // this.Router.navigate([redirectTo ]);
        this.AuthService.logout();
        return false;
      }
    }
    if (this.AuthService.loggedIn()) {
      return true;
    } else if (this.AuthService.loggedIn() == false) {
      this.AuthService.logout();
      return false;
    }

    // // 3rd: verify if token is expired
    // let isTokenExpired = await this.AuthService.isTokenExpired();
    // if (!isTokenExpired) {
    //   this.Router.navigate([redirectTo]);
    //   return false;
    // }

    return true;
  }
}
