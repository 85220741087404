<div mat-dialog-title class="dialog-title">
  <h2>Photo</h2>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div class="main-container">
    <!-- <input type="file" accept="image/*" #fileInput (change)="onFileChange($event, fileInput)" /> -->

    <div>
      <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
      <input hidden (change)="onFileChange($event, fileInput)" #fileInput type="file" id="file" />    
      <div *ngIf="fileName" class="form-field">Filename: {{ fileName }}</div>  
    </div>
    <mat-form-field class="form-field">
      <mat-label>Select an option</mat-label>
      <mat-select [(value)]="type">
        <mat-option value="desktop"> Desktop </mat-option>
        <mat-option value="mobile"> Mobile </mat-option>
      </mat-select>
    </mat-form-field>
    
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button (click)="submit()" [disabled]="!fileName">Submit</button>
</div>
