import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ReactiveFormsModule,
  FormsModule,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  Form,
} from '@angular/forms';

import { DataService } from './data.service';
import { InputValidationService } from './inputValidation.service';

@Injectable()
export class FormService {
  constructor(
    private InputValidation: InputValidationService,
    private DataService: DataService,
    private FormBuilder: FormBuilder
  ) {}

  newForm_data: FormGroup;
  password_length: number = 6;

  public markFormGroupTouched(formGroup: FormGroup) {
    // (<any>Object).values(formGroup.controls).forEach(control => {
    //   control.markAsTouched();

    //   if (control.controls) {
    //     this.markFormGroupTouched(control);
    //   }
    // });

    (<any>Object).keys(formGroup.controls).map((control) => {
      // console.log('latest =>', formGroup.controls[control])
      formGroup.controls[control].markAsTouched();
      // if (formGroup.controls[control]['controls']) {
      //   this.markFormGroupTouched(formGroup.controls[control]);
      // }
    });

    // (<any>Object).values(formGroup.controls).forEach(control => {
    //   // console.log('previous =>', control)
    //   control.markAsTouched();
    //   if (control['controls']) {
    //     this.markFormGroupTouched(control);
    //   }
    // });
  }

  registerForm() {
    this.newForm_data = this.FormBuilder.group({
      email: ['', Validators.required],
      password: [
        '',
        
      ],
      confirm_password: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      date_of_birth: [ '', Validators.required],
      phone_number: ['', Validators.required],
      gender: ['', Validators.required],
    });
    return this.newForm_data; 
  }

  loginForm() {
    this.newForm_data = this.FormBuilder.group({
      username: [
        '',
        [
          Validators.required,
          // Validators.pattern(this.InputValidation.userNamePattern),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          // Validators.minLength(this.password_length),
          // this.InputValidation.password_hasNumber(),
          // this.InputValidation.password_hasCapitalCase(),
          // this.InputValidation.password_hasSmallCase(),
          //this.InputValidation.password_hasSpecialCharacters()
        ],
      ],
      // confirmPassword: ['', Validators.required],
      // acceptTerms: [false, Validators.requiredTrue]
      // }, {
      //     validator: [
      //       this.InputValidation.MatchValue('email', 'confirmEmail'),
      //       this.InputValidation.MatchValue('password', 'confirmPassword')
      //     ]
    });
    return this.newForm_data;
  }

  redirect_loginForm(id) {
    this.newForm_data = this.FormBuilder.group({
      input: [
        '',
        [
          Validators.required,
          // (id == "home") ?Validators.pattern(this.InputValidation['emailPattern']) :Validators.pattern(this.InputValidation['namePattern_en']),
        ],
      ],
    });
    return this.newForm_data;
  }

  // registerForm() {
  //   this.newForm_data = this.FormBuilder.group({
  //     // salutation: ['', Validators.required],
  //     // barcode: ['', Validators.required],
  //     // salutation: ['', Validators.required],
  //     first_name: ['', [
  //       Validators.required,
  //       Validators.pattern(this.InputValidation.namePattern_en),
  //     ]],
  //     last_name: ['', [
  //       Validators.required,
  //       Validators.pattern(this.InputValidation.namePattern_en),
  //     ]],
  //     // job_title: [''],
  //     // country_name: ['', Validators.required],
  //     // company: ['', Validators.required],
  //     // company: ['', [
  //     //   Validators.required,
  //     //   Validators.pattern(this.InputValidation.companyPattern),
  //     // ]],
  //     // address_line_1: [''],
  //     // address_line_2: [''],
  //     // city: [''],
  //     // state: [''],
  //     // postal_code: [''],
  //     email: ['', [
  //       Validators.required,
  //       Validators.pattern(this.InputValidation.emailPattern)
  //     ]],
  //     confirmEmail: ['', [
  //       Validators.required,
  //       Validators.pattern(this.InputValidation.emailPattern)
  //     ]],
  //     // phone_country_code: [''],
  //     phone: ['', [
  //       Validators.required,
  //       Validators.pattern(this.InputValidation.phoneNumberPattern)
  //     ]],
  //     username: ['', [
  //       Validators.required,
  //       Validators.pattern(this.InputValidation.userNamePattern),
  //     ]],
  //     // mobile_country_code: [''],
  //     // mobile_phone: [''],
  //     password: ['', [
  //       Validators.required,
  //       Validators.minLength(this.password_length),
  //       this.InputValidation.password_hasNumber(),
  //       this.InputValidation.password_hasCapitalCase(),
  //       this.InputValidation.password_hasSmallCase(),
  //       //this.InputValidation.password_hasSpecialCharacters()
  //     ]],
  //     confirmPassword: ['', Validators.required],
  //     // acceptTerms: [false, Validators.requiredTrue]
  //   }, {
  //     validator: [
  //       this.InputValidation.MatchValue('email', 'confirmEmail'),
  //       this.InputValidation.MatchValue('password', 'confirmPassword')
  //     ]
  //   });
  //   return this.newForm_data;
  // }

  voucherForm() {
    this.newForm_data = this.FormBuilder.group({
      // salutation: ['', Validators.required],
      voucher_code: ['', Validators.required],
    });
    return this.newForm_data;
  }

  
}
