<mat-toolbar color="primary" style="height: 6vh">
  <a mat-button class="text-decoration-none" (click)="switchTo('original')">Display</a>
  <a mat-button class="text-decoration-none" (click)="switchTo('recyle')">Hide</a>
  <a mat-button class="text-decoration-none" (click)="openDialog()">Generate Dummy Image</a>
  <a mat-button class="text-decoration-none" (click)="openUploadBannerDialog()">Banner </a>
</mat-toolbar>
<!-- <div class="bg-dark sticky-top py-2 text-white">
  <a mat-button class="text-decoration-none" (click)="switchTo('original')"
    >Display</a
  >
  <a mat-button class="text-decoration-none" (click)="switchTo('recyle')"
    >Hide</a
  >
  <a mat-button class="text-decoration-none" (click)="openDialog()"
    >Generate Dummy Image</a
  >
</div> -->
<mat-grid-list style="margin-top: 10px" [cols]="windowSize <= 520 ? 2 : 4" gutterSize="10px">
  <mat-grid-tile *ngFor="let photo of photos">
    <div
      class="full-container"
      (click)="onHandleImage(photo)"
      longPress
      (mouseLongPress)="openImage(photo)"
      [ngStyle]="photo.selected == true ? { border: '2px solid rgb(242, 8, 8)' } : ''"
    >
      <div class="image-container" [ngStyle]="{ 'background-image': 'url(' + photo.url + ')' }">
        <ng-container>
          <!-- <img class="image" loading="lazy" src="{{ photo.url }}" /> -->
        </ng-container>
      </div>
      <div class="text-container">{{ photo.filename }}</div>
    </div>
    <!-- <a
    (click)="onHandleImage(photo)"          
  >
    <img class="img-fluid rounded" loading="lazy" src="{{ photo.url }}" />         
  </a> -->
    <!-- <div (click)="onHandleImage(photo)"
      style="display: flex; flex-direction: column;"
      [ngStyle]="
        photo.selected == true ? { border: '2px solid rgb(242, 8, 8)' } : ''
      "
    >
      <div style="flex: 4; background-color: blue;">
        <img class="aha" loading="lazy" src="{{ photo.url }}" />
      </div>
      <div style="flex: 1; background-color: green"></div> 
    </div> -->
  </mat-grid-tile>
</mat-grid-list>

<!-- <div class="fixed-bottom bg-dark py-2 text-white d-flex flex-row-reverse px-3">
  <button
    (click)="switchToType(type)"
    mat-button
    [matBadge]="selectedPhotoLength"
    matBadgeColor="warn"
    style="border: none"
  >
    {{ type == "original" ? "Hide" : "Unhide" }}
  </button>
</div> -->
<div class="footer bg-dark" #footer>
  <button mat-button (click)="downloadAll()">Download All</button>
  <button
    (click)="switchToType(type)"
    mat-button
    [matBadge]="selectedPhotoLength"
    matBadgeColor="warn"
    style="border: none; position: absolute; right: 2vw"
  >
    {{ type == 'original' ? 'Hide' : 'Unhide' }}
  </button>
</div>
