import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class ApiBackEndService {
  constructor(private http: HttpClient) {}

  api_post_url = {
    user_register: 'user/register',
    user_login: 'user/login',
    check_exist: 'photo/check_exist',
    get_images: 'photo/get_images',
    switch_photos: 'photo/switch_photos',
    create_dummy_photos: 'photo/create_dummy_photos',
    check_valid: 'photo/check_valid',
    download_all_pictures: 'photo/download_all_pictures'
  }

  api_get_url = {}

  getBackEnd_url() {
    // return 'http://localhost:3000/api';
    return '/api'
  }

  verifyToken(data) {
    return this.http.post(`${this.getBackEnd_url()}/verifytoken`, { data }, httpOptions)
  }

  onsite_get_token(data) {
    return this.http.post(`${this.getBackEnd_url()}/user/onsite_get_token`, { data }, httpOptions)
  }

  api_post(name, data?) {
    let url = ``
    url = this.api_post_url[`${name}`]
    return this.http.post(`${this.getBackEnd_url()}/${url}`, { data }, httpOptions)
  }

  api_get(name) {}

  login(data) {
    return this.http.post(`${this.getBackEnd_url()}/user/login`, { data }, httpOptions)
  }

  get(path, data?) {
    return this.http.post(`${this.getBackEnd_url()}/get/${path}`, { data }, httpOptions)
  }
}
