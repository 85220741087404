import { Injectable } from '@angular/core'

import { ApiBackEndService } from '../services/api-back-end.service'
import { EncdecService } from '../services/encdec.service'
import { AuthService } from '../services/auth.service'
import { HttpErrorResponse, HttpResponse, HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ApiFrontEndService {
  constructor(
    private ApiBackEndService: ApiBackEndService,
    private EncdecService: EncdecService,
    private AuthService: AuthService,
    private http: HttpClient
  ) {}

  getBackEnd_url() {
    // return 'http://localhost:3000/api';
    return this.ApiBackEndService.getBackEnd_url();
  }

  public onsite_get_token(data) {
    return new Promise<any>((resolve, reject) => {
      data = this.EncdecService.encrypt('key', data)
      this.ApiBackEndService.onsite_get_token(data).subscribe(
        (res: any) => {
          resolve(res)
        },
        (err) => {
          reject(err)
        }
      )
    })
  }

  public verifyToken(token?) {
    return new Promise<any>((resolve, reject) => {
      if (!token) token = this.AuthService.getToken()
      if (!token) reject('Invalid token')
      else if (token) {
        this.ApiBackEndService.verifyToken(token).subscribe(
          (res: any) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
      }
    })
  }

  public api_post(name, data?) {
    if (data) data = this.EncdecService.encrypt('key', data)
    return new Promise<any>((resolve, reject) => {
      this.ApiBackEndService.api_post(name, data).subscribe(
        (res: any) => {
          if (typeof res == 'string') res = this.EncdecService.decrypt('key', res)
          resolve(res)
        },
        (err) => {
          reject(err)
        }
      )
    })
  }

  public to(path, data?) {
    if (data) data = this.EncdecService.encrypt('key', data)
    return new Promise<any>((resolve, reject) => {
      this.ApiBackEndService[path](data).subscribe(
        (res: any) => {
          if (typeof res == 'string') res = this.EncdecService.decrypt('key', res)
          resolve(res)
        },
        (err) => {
          reject(err)
        }
      )
    })
  }
  public get(path, data?) {
    if (data) data = this.EncdecService.encrypt('key', data)
    return new Promise<any>((resolve, reject) => {
      this.ApiBackEndService.get(path, data).subscribe(
        (res: any) => {
          if (typeof res == 'string') res = this.EncdecService.decrypt('key', res)
          resolve(res)
        },
        (err) => {
          reject(err)
        }
      )
    })
  }

  public downloadAllPictures(data): Observable<HttpResponse<Blob>> {
    if (data) data = this.EncdecService.encrypt('key', data)
    return this.http.post(
      `${this.ApiBackEndService.getBackEnd_url()}/photo/download_all_pictures`,
      { data },
      { observe: 'response', responseType: 'blob' }
    )
  }
}
