import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { NgxSpinnerService } from 'ngx-spinner'
import { FileUploadService } from 'src/app/services/file-upload.service'

@Component({
  selector: 'app-upload-banner-dialog',
  templateUrl: './upload-banner-dialog.component.html',
  styleUrls: ['./upload-banner-dialog.component.scss']
})
export class UploadBannerDialogComponent implements OnInit {
  type: any
  formData: FormData
  id: any
  fileName: any
  constructor(
    public dialogRef: MatDialogRef<UploadBannerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private FUS: FileUploadService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.type = 'desktop'
    this.id = this.data.id
  }

  onFileChange(event: any, fileInput: any) {
    const formData = new FormData()
    let file = event.target.files[0] as File
    this.fileName = file.name
    formData.append('banner', file)
    fileInput.value = ''
    this.formData = formData
  }

  async submit() {
    try {
      this.spinner.show()
      let test = await this.FUS.upload_banner(this.formData, this.id, this.type).subscribe(
        (res: any) => {
          this.dialogRef.close(true)
        },
        (err: any) => {
          this.dialogRef.close(false)
        }
      )
    } catch (err) {
    } finally {
      this.spinner.hide()
    }
  }

  onCancelClick(): void {
    this.dialogRef.close(false)
  }
}
