<h1 mat-dialog-title>Please input number to create dummy image</h1>
<div mat-dialog-content>
  <p>Input the prefix and number</p>
  <form [formGroup]="myForm">
    <mat-form-field class="col-6 col-sm-3">
      <mat-label>Prefix</mat-label>
      <input #prefix
        type="text"
        placeholder="prefix"
        matInput
        formControlName="prefix"        
        (ngModelChange)="onChange()"
      />
      <mat-error *ngIf="myForm.get('prefix').hasError('required')"
        >Prefix is required</mat-error
      >
    </mat-form-field>
    <mat-form-field class="col-6 col-sm-3">
      <mat-label>Number</mat-label>
      <input
        type="number"
        min="0"
        max="100"
        placeholder="number"
        matInput
        formControlName="number"
        (ngModelChange)="onChange()"
      />
      <mat-error *ngIf="myForm.get('number').hasError('required')"
        >Number is required</mat-error
      >
      <mat-error
        *ngIf="
          myForm.get('number').hasError('max') ||
          myForm.get('number').hasError('min')
        "
        >Only 1 to 500 is allowed</mat-error
      >
    </mat-form-field>
    <button class="btn btn-primary" type="button" (click)="checkValid()" [disabled]="!myForm.valid">
      Check
    </button>
  </form>
  <div class="col-3 col-md-6">
    <p>
      Expected Result:
      <ng-container
        *ngIf="
          example != null
        "
        >{{example}}
      </ng-container>
    </p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    (click)="submit()"
    [disabled]="!allowSubmit"
    cdkFocusInitial
  >
    Ok
  </button>
</div>
