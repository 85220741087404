import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiFrontEndService } from 'src/app/services/api-front-end.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.scss'],
})
export class AccountLoginComponent implements OnInit {
  loginForm: FormGroup;
  errMsg: String = null;
  constructor(
    private API: ApiFrontEndService,
    private FS: FormService,
    private fb: FormBuilder,
    private AS: AuthService,
    private DS: DataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  async submit() {
    if (this.loginForm.valid) {
    try{
      let data = {
        ...this.loginForm.value,
      };
      let result = await this.API.api_post('user_login', data);
      if(typeof result == 'string' ) throw result;
      else if(result.token) {
        this.AS.setToken(result.token);
        this.DS.construct_user();
        this.navigate_to();
      }
      
    }   
    catch(err){
      this.errMsg = 'INCORRECT USERNAME OR PASSWORD';
    }
    }
  }

  navigate_to() {
    this.router.navigate(['/admin/home']);
  }
}
